import { Text, Flex, Heading } from "@chakra-ui/react";

import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import React from "react";

export default function FeatureCard({ title, icon, content, ...rest }) {
  return (
    <Card
      textAlign="left"
      maxW="370px"
      borderRadius="10px"
      bg="#fff"
      p="30px 25px"
      w="100%"
      direction={{ base: "column" }}
      overflow="hidden"
      position="relative"
      boxShadow="0px 20px 60px #F9F1FF"
      {...rest}
    >
      <Flex alignItems="center">
        <IconBox icon={icon} h="80px" w="82px" />
      </Flex>
      <Heading as="h3" size="h3" mt={{ base: "18px" }} mb={{ base: "8px" }}>
        {title}
      </Heading>
      <Text fontWeight="400"> {content} </Text>
    </Card>
  );
}
