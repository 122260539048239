import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  Show,
  SimpleGrid,
  Square,
  Stack,
  Text,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import ReactFloaterJs from "react-floaterjs";
import { BeatLoader } from "react-spinners";

import roadmapTaskImage from "assets/img/roadmap/roadmap-task.png";
import roadmapCalculatorImage from "assets/img/roadmap/roadmap-calculator.png";
import walletRoadImg from "assets/img/roadmap/wallet.png";

import tokenomicsHandImage from "assets/img/tokenomics/tokenomics-hand.png";
import tokenomicsDecorationImage from "assets/img/tokenomics/tokenomics-decoration.svg";
import tokenomicsBg from "assets/img/tokenomics/tokenomics-bg.svg";

import heroGadgetCoinImage from "assets/img/hero/hero-gadget-coin.png";
import heroGadgetWalletImage from "assets/img/hero/hero-gadget-wallet.png";
import heroGadgetSquiggleLineImage from "assets/img/hero/hero-gadget-squiggle-line.png";

import PieChart from "components/charts/PieChart";
import { HeroDotGroupBottomRightIcon } from "components/icons/Icons";
import RoadmapCard from "pages/landing-page/components/RoadmapCard";
import SectionContainer from "pages/landing-page/components/SectionContainer";

export default function Tokenomics() {
  const [isLargerThan400] = useMediaQuery("(min-width: 400px)");
  const [emailSubscribed, setEmailSubscribed] = useState("");

  const onClickHandler = (e) => {
    e.preventDefault();
    const submitPOST = async () => {
      if (!emailSubscribed) return toast.error("Please fill your email!");

      const reg =
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{3,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!reg.test(String(emailSubscribed).toLowerCase())) {
        return toast.error(`${emailSubscribed} is not a valid email address.`);
      }

      try {
        const data = { email: emailSubscribed };

        // eslint-disable-next-line no-undef
        Pageclip.send(
          "EYGflzPn9EbMEYSrRu6xicE4GMAydVMY",
          "subscribe",
          data,
          function (error, res) {
            if (error) return toast.error("Please try again!");

            if (res.data === "ok") {
              toast.success("Thank you. Submit successful!");
              setEmailSubscribed("");
            }
          }
        );
      } catch (error) {
        console.log("error", error);
      }
    };
    submitPOST();
  };
  return (
    <Flex
      id="tokenomics-section"
      zIndex="2"
      w="100%"
      minH="751px"
      direction="column"
      overflow="hidden"
      position="relative"
      bgImage={tokenomicsBg}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      backgroundPosition="bottom"
    >
      <Show above="lg">
        {/* feature decoration image */}
        <Image
          width="384"
          height="774"
          src={tokenomicsDecorationImage}
          top={["20%"]}
          left="0"
          position="absolute"
        />
        {/* HeroDotGroupBottomRightIcon tokenomics Section */}
        <HeroDotGroupBottomRightIcon
          height="44px"
          width="120px"
          right="-40px"
          top={"39%"}
          position={{ base: "relative", lg: "absolute" }}
        />
      </Show>

      {/* tokenomics Section */}
      <SectionContainer
        px={{ base: "20px", xl: "135px" }}
        pt={{ base: "10px", xl: "32px" }}
        mt={{ base: "20px", xl: "0px" }}
        position="relative"
        textAlign={{ base: "center", xl: "left" }}
      >
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          width="full"
          minH="606px"
        >
          {!isLargerThan400 ? (
            <Box mb="26px" data-aos="fade-up" data-aos-delay="200">
              <Heading
                as="span"
                size="h1"
                fontSize={{ base: "4xl", md: "6xl" }}
                lineHeight={{ base: "42px", md: "70px" }}
              >
                Whale{" "}
                <Heading
                  variant="underline"
                  as="span"
                  size="h4"
                  fontSize={{ base: "4xl", md: "6xl" }}
                  lineHeight={{ base: "42px", md: "70px" }}
                >
                  Tokenomics{" "}
                </Heading>
              </Heading>

              <Heading as="h3" size="h3" my={{ base: "10px" }}>
                Total Supply:{" "}
                <Heading as="span" size="h3" color="decoration.4">
                  1B INW Tokens{" "}
                </Heading>
              </Heading>
            </Box>
          ) : null}

          <Box w="full" maxW={{ base: "100%", md: "55%" }}>
            <Flex
              data-aos="fade-up"
              data-aos-delay="100"
              alignItems="center"
              justifyContent="center"
              position="relative"
              minH={{ base: "fit-content", xl: "627px" }}
            >
              {/* hero-gadget-coin */}
              <Show above="lg">
                <Box
                  duration="200ms"
                  w={["45px", "77px"]}
                  left={["3%", "-12%"]}
                  top={["72px", "14%"]}
                  position={{ base: "absolute", lg: "absolute" }}
                >
                  <ReactFloaterJs>
                    <Image src={heroGadgetCoinImage} />
                  </ReactFloaterJs>
                </Box>

                {/* small hero-gadget-coin */}
                <Box
                  duration="200ms"
                  transform="scaleX(-1)"
                  w={["55px"]}
                  right={["3%", "14%"]}
                  top={["72px", "23%"]}
                  position={{ base: "absolute", lg: "absolute" }}
                >
                  <ReactFloaterJs>
                    <Image src={heroGadgetCoinImage} />
                  </ReactFloaterJs>
                </Box>
              </Show>
              {/* tokenomicsHandImage Image */}
              <Image
                transform="rotate(-3.26deg)"
                filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
                width="full"
                height="full"
                maxWidth={{
                  base: "280px",
                  // md: "400px",
                  // lg: "500px",
                  // xl: "600px",
                  "2xl": "496px",
                }}
                maxHeight={{
                  base: "358px",
                  // md: "358px",
                  // lg: "448px",
                  // xl: "537px",
                  "2xl": "632px",
                }}
                src={tokenomicsHandImage}
              />
              <VStack
                transform="rotate(-3.26deg)"
                position="absolute"
                w={{ base: "120px", xl: "240px" }}
                left={{
                  base: "25%",
                  sm: "calc(50% - 80px)",
                  md: "calc(50% - 80px)",
                  xl: "calc(50% - 150px)",
                  "2xl": "25%",
                }}
                top="6%"
              >
                <Square
                  transform={{
                    base: "translateX(-3px)",
                    xl: "translateX(-10px)",
                  }}
                  position="relative"
                  h={{ base: "160px", xl: "240px" }}
                >
                  <PieChart
                    h="100%"
                    w="100%"
                    chartOptions={pieChartOptions}
                    chartData={pieChartData.chartData}
                  />
                  <Box top="33%" textAlign="center" position="absolute">
                    <Text
                      transform="rotate(-3.26deg)"
                      fontWeight="600"
                      fontSize={{ base: "9px", xl: "13px" }}
                      color="text.500"
                      lineHeight={{ base: "14px", xl: "30px" }}
                    >
                      Total Supply
                    </Text>
                    <Heading
                      transform="rotate(-3.26deg)"
                      as="h4"
                      size="h4"
                      fontSize={{ base: "14px", xl: "24px" }}
                      fontWeight="700"
                      lineHeight={{ base: "14px", xl: "16px" }}
                    >
                      {" "}
                      1B INW
                    </Heading>
                  </Box>
                </Square>

                <SimpleGrid
                  transform={{
                    base: "translateY(-24px)",
                    xl: "translateY(0px)",
                  }}
                  columns={{ base: "2" }}
                  gap={{ base: "10px", xl: "30px" }}
                >
                  {[...Array(pieChartData?.chartData?.length)].map((_, idx) => (
                    <Flex
                      key={idx}
                      ml={{ base: "2px", xl: "8px" }}
                      alignItems="start"
                      transform="rotate(-0.2deg)"
                    >
                      <Circle
                        size={{ base: "12px", xl: "24px" }}
                        bg={pieChartData?.colors[idx]}
                      />
                      <Box w="full" ml={{ base: "2px", xl: "6px" }}>
                        <Heading
                          fontSize={{ base: "12px", xl: "18px" }}
                          as="h4"
                          size="h4"
                          lineHeight={{ base: "12px", xl: "16px" }}
                        >
                          {pieChartData?.chartData[idx]}%
                        </Heading>
                        <Text
                          mt="4px"
                          fontWeight="500"
                          lineHeight={{ base: "10px", xl: "16px" }}
                          fontSize={{ base: "8px", xl: "12px" }}
                        >
                          {pieChartData?.labels[idx]}
                        </Text>
                      </Box>
                    </Flex>
                  ))}
                </SimpleGrid>
              </VStack>
            </Flex>
          </Box>

          <Box
            w="full"
            px="8px"
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            position="relative"
            maxW={{ base: "100%", md: "45%" }}
          >
            {/* hero-gadget-squiggle-line */}
            <Show above="lg">
              <Box
                duration="200ms"
                right={{ base: "0%", md: "-10%" }}
                top={{ base: "0%", md: "-4%" }}
                position={{ base: "absolute", lg: "absolute" }}
              >
                <ReactFloaterJs>
                  <Image src={heroGadgetSquiggleLineImage} />
                </ReactFloaterJs>
              </Box>
            </Show>
            {isLargerThan400 ? (
              <>
                <Heading
                  w="full"
                  textAlign={{ base: "center", md: "left" }}
                  data-aos="fade-up"
                  data-aos-delay="200"
                  as="span"
                  size="h1"
                  fontSize={{ base: "4xl", md: "6xl" }}
                  lineHeight={{ base: "42px", md: "70px" }}
                >
                  INW{" "}
                  <Heading
                    variant="underline"
                    as="span"
                    size="h1"
                    fontSize={{ base: "4xl", md: "6xl" }}
                    lineHeight={{ base: "42px", md: "70px" }}
                  >
                    Tokenomics{" "}
                  </Heading>
                </Heading>

                <Heading
                  textAlign={{ base: "center", md: "left" }}
                  w="full"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  as="h2"
                  size="h2"
                  my={{ base: "10px" }}
                >
                  Total Supply:{" "}
                  <Heading as="span" size="h2" color="decoration.4">
                    1B INW Tokens{" "}
                  </Heading>
                </Heading>
              </>
            ) : null}
            <Text
              data-aos="fade-up"
              data-aos-delay="300"
              textAlign="justify"
              mt={{ base: "34px", md: "16px" }}
              mb={{ base: "28px", md: "30px" }}
            >
              INW token is the Core of INK WHALE platform. Users use $INW to pay
              for transaction fees when creating new pool and unstaking their
              locked tokens or NFTs. Collected Fees are burnt immediately after
              successful transactions. There is no fee applied when staking or
              claiming rewards.
            </Text>
            <Button
              w="174px"
              data-aos="fade-up"
              data-aos-delay="300"
              mx={{ base: "auto", xl: "unset" }}
              onClick={() =>
                window.open(
                  "https://inkwhale.gitbook.io/inkwhale-docs/",
                  "_blank"
                )
              }
            >
              Learn More
            </Button>
          </Box>
        </Flex>
        <div id="roadmap"></div>
      </SectionContainer>

      {/* Roadmap Section */}
      <SectionContainer
        px={{ base: "20px", xl: "135px" }}
        pt={{ base: "10px", xl: "72px" }}
        pb={{ base: "10px", xl: "32px" }}
        mt={{ base: "42px", xl: "60px" }}
        position="relative"
        textAlign={{ base: "center", xl: "center" }}
      >
        <Flex
          data-aos="fade-up"
          data-aos-delay="100"
          w="full"
          justifyContent={{ base: "center", xl: "center" }}
          mb="72px"
        >
          {" "}
          <Heading
            as="span"
            size="h1"
            fontSize={{ base: "4xl", md: "6xl" }}
            lineHeight={{ base: "42px", md: "70px" }}
          >
            Our{" "}
            <Heading
              variant="underline"
              as="span"
              size="h1"
              fontSize={{ base: "4xl", md: "6xl" }}
              lineHeight={{ base: "42px", md: "70px" }}
            >
              Roadmap{" "}
            </Heading>
          </Heading>
        </Flex>
        <SimpleGrid
          data-aos="fade-up"
          data-aos-delay="200"
          columns={{ base: "1", md: "3" }}
          gap="30px"
        >
          {roadmapData.map((item) => (
            <RoadmapCard key={item.title} {...item} />
          ))}
        </SimpleGrid>
      </SectionContainer>

      {/* Subscribe Section */}
      <SectionContainer
        px={{ base: "20px", xl: "135px" }}
        pt={{ base: "32px", xl: "32px" }}
        pb={{ base: "10px", xl: "10px" }}
        mt={{ base: "42px", xl: "60px" }}
        position="relative"
        minH={{ base: "354px", xl: "414px" }}
        textAlign={{ base: "center", xl: "center" }}
      >
        {/* hero-gadget-squiggle-line */}
        <Box
          duration="200ms"
          left={{ base: "83%", xl: "15%" }}
          top={{ base: "0%", xl: "4%" }}
          position={{ base: "absolute", lg: "absolute" }}
        >
          <ReactFloaterJs>
            <Image src={heroGadgetSquiggleLineImage} />
          </ReactFloaterJs>
        </Box>

        {/* hero-gadget-coin */}
        <Box
          duration="200ms"
          w={["45px", "77px"]}
          left={["-4%", "4%"]}
          bottom={["83%", "35%"]}
          position={{ base: "absolute", lg: "absolute" }}
        >
          <ReactFloaterJs>
            <Image src={heroGadgetCoinImage} />
          </ReactFloaterJs>
        </Box>

        {/* hero-gadget-wallet */}
        <Show above="lg">
          <Box
            duration="200ms"
            right={["-125px", "9%"]}
            w="130px"
            top="10%"
            position={{ base: "absolute", lg: "absolute" }}
          >
            <ReactFloaterJs>
              <Image src={heroGadgetWalletImage} />
            </ReactFloaterJs>
          </Box>
        </Show>
        <VStack w="full" justifyContent={{ base: "center", xl: "center" }}>
          {" "}
          <Heading
            data-aos="fade-up"
            data-aos-delay="100"
            as="span"
            size="h1"
            fontSize={{ base: "4xl", md: "6xl" }}
            lineHeight={{ base: "42px", md: "70px" }}
          >
            Keep in touch
          </Heading>
          <Text pt="2px" data-aos="fade-up" data-aos-delay="100">
            Ink Whale lets you earn fixed interest and other rewards
          </Text>
        </VStack>

        <Stack pt={["8px", "26px"]} pb={["28px", "32px"]}>
          <InputGroup
            minW={["100%", "500px"]}
            data-aos="fade-up"
            data-aos-delay="200"
            mx="auto"
            maxW={["500px"]}
            w="full"
            bg="#FFFF"
            h={["52px"]}
            color="text.2"
            border="0px solid #06A9B4"
            borderRadius="10px"
          >
            <Input
              boxShadow="0px 20px 60px #F9F1FF"
              _focus={{ background: "#FFF" }}
              _active={{ background: "#FFF" }}
              bg="#FFF"
              h={["52px"]}
              autoComplete="off"
              type="email"
              name="email"
              id="email"
              required
              value={emailSubscribed}
              onChange={({ target }) => setEmailSubscribed(target.value)}
              variant="unstyled"
              px={5}
              placeholder="Enter your email"
              _placeholder={{ color: "#text.2" }}
              borderRadius="10px"
            />
          </InputGroup>
        </Stack>
        <Button
          data-aos="fade-up"
          data-aos-delay="200"
          mx="auto"
          w="140px"
          spinner={<BeatLoader size={8} color="white" />}
          onClick={onClickHandler}
        >
          Subscribe
        </Button>
      </SectionContainer>
    </Flex>
  );
}

const pieChartData = {
  chartData: [60, 10, 5, 10, 5, 10],
  labels: [
    "Rewards",
    "Public Sale",
    "Strategic Sale",
    "Treasury",
    "Airdrop Partner Bounty",
    "Founding Team",
  ],
  colors: ["#98EAEF", "#CDA1F6", "#FFDA6E", "#F59A86", "#7EB1EA", "#FFA0D2"],
};
const pieChartOptions = {
  labels: pieChartData.labels,
  colors: [],
  chart: {
    type: "donut",
  },
  states: {
    hover: {
      filter: {
        type: "none",
      },
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  hover: { mode: null },
  plotOptions: {
    pie: {
      customScale: 1,
      expandOnClick: false,
      donut: {
        labels: {
          show: false,
        },
      },
    },
  },
  fill: {
    colors: pieChartData.colors,
  },
  tooltip: {
    enabled: true,
    theme: "light",
    custom: ({ series, seriesIndex, w }) => {
      return `${w.globals.labels[[seriesIndex]]}: ${series[seriesIndex]}%`;
    },
  },
};
const roadmapData = [
  {
    imageUrl: roadmapTaskImage,
    title: "Q2 - 2024",
    bgColor: "#D3F3F5",
    contentList: [
      "INW2 RELEASE: Convert INW (version 1) to INW2 (version 2) at a 1:1 ratio.",
      "INW2 LISTING: Trade on Common AMM",
      "LAUNCHPAD RELEASE: Launch your token with InkWhale.",
    ],
  },
  {
    imageUrl: roadmapCalculatorImage,
    bgColor: "#EBD4FF",
    title: "Q3 - 2024",
    contentList: [
      "$AZERO STAKING: Stake $AZERO, earn $AZERO & $INW2 with quick unstaking in 48hrs.",
      "Launch Ink Whale platform on 5irechain  network",
      "More features",
      "Multi-transfer / presale airdrop, improve Launchpad features",
    ],
  },
  {
    imageUrl: walletRoadImg,
    bgColor: "#FFD6EB",
    title: "Q4 - 2024 & beyond",
    contentList: [
      "More features",
      "Community development",
      "CEX listing",
      "Multi-chain expansion",
    ],
  },
];
