import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Heading,
  Show,
  SimpleGrid,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";

import featureWalletImage from "assets/img/features/feature-wallet.png";
import featureCurveImage from "assets/img/features/feature-curve.svg";
import featureDecorationImage from "assets/img/features/feature-decoration.svg";

import {
  FeatureIcon1,
  FeatureIcon2,
  FeatureIcon3,
  FeatureIcon4,
  FeatureIcon5,
  FeatureIcon6,
  FeatureIcon7,
  FeatureIcon8,
  HeroDotGroupTopLeftIcon,
  HeroDotGroupBottomRightIcon,
} from "components/icons/Icons";
import FeatureCard from "pages/landing-page/components/FeatureCard";
import FeatureSmallCard from "pages/landing-page/components/FeatureSmallCard";
import FeatureSwipeCard from "pages/landing-page/components/FeatureSwipeCard";

import SectionContainer from "pages/landing-page/components/SectionContainer";
import { FeatureIcon9 } from "components/icons/Icons";
import { FeatureIcon10 } from "components/icons/Icons";

export default function Features() {
  const [isLargerThan415] = useMediaQuery("(min-width: 415px)");
  const [isLargerThan770] = useMediaQuery("(min-width: 1200px)");

  return (
    <Flex
      id="features-section"
      zIndex="2"
      w="100%"
      minH="751px"
      direction="column"
      overflow="hidden"
      position="relative"
      background="linear-gradient(180deg, #FAF4FF 26.66%, rgba(251, 246, 252, 0) 100%)"
    >
      <Show above="lg">
        {/* feature decoration image */}
        <Image
          width="384"
          height="774"
          src={featureDecorationImage}
          top={["20%"]}
          right="0"
          position="absolute"
        />

        {/* vertical dot  left */}
        <HeroDotGroupTopLeftIcon
          width={["30px", "44px"]}
          height={["82px", "120px"]}
          top={["60%", "51%"]}
          left="0"
          position="absolute"
        />
        {/* vertical dot top right */}

        <HeroDotGroupBottomRightIcon
          height="44px"
          width="120px"
          right="-40px"
          top={"-25px"}
          position={{ base: "relative", lg: "absolute" }}
        />
      </Show>
      {/* vertical dot right*/}
      <HeroDotGroupTopLeftIcon
        right="0"
        bottom={["60%", "14%"]}
        width={["30px", "44px"]}
        height={["82px", "120px"]}
        position="absolute"
      />
      <SectionContainer
        textAlign={{ base: "center", xl: "left" }}
        px={{ base: "20px", xl: "135px" }}
        pt={{ base: "10px", xl: "32px" }}
        mt={{ base: "20px", xl: "60px" }}
        position="relative"
      >
        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          width="full"
          minH="606px"
        >
          {!isLargerThan415 ? (
            <Box mb="26px" data-aos="fade-up" data-aos-delay="100">
              <Heading
                as="span"
                size="h1"
                fontSize={{ base: "4xl", md: "6xl" }}
                lineHeight={{ base: "42px", md: "70px" }}
              >
                Why{" "}
              </Heading>

              <Heading
                as="span"
                size="h1"
                variant="underline"
                fontSize={{ base: "4xl", md: "6xl" }}
                lineHeight={{ base: "42px", md: "70px" }}
              >
                Ink Whale
              </Heading>
              <Text
                mt={{ base: "14px", md: "16px" }}
                mb={{ base: "28px", md: "44px" }}
              >
                Staking and Yield Farming platform on Aleph Zero
              </Text>
              <FeatureSwipeCard dataList={featuresData} />
            </Box>
          ) : (
            <Box
              data-aos="fade-up"
              data-aos-delay="100"
              w="full"
              maxW={{ base: "100%", lg: "65%" }}
            >
              <SimpleGrid columns={{ base: "1", sm: "2" }} gap="30px">
                <SimpleGrid
                  columns="1"
                  gap="30px"
                  mt={{ base: "-30px", xl: "-70px" }}
                >
                  {featuresData.slice(0, 3).map((item, idx) => (
                    <FeatureCard key={idx} {...item} />
                  ))}
                </SimpleGrid>

                <SimpleGrid columns="1" gap="30px">
                  {featuresData.slice(3, 6).map((item, idx) => (
                    <FeatureCard key={idx} {...item} />
                  ))}
                </SimpleGrid>
              
                
              </SimpleGrid>
            </Box>
          )}

          <Box
            data-aos="fade-up"
            data-aos-delay="200"
            w="full"
            maxW={{ base: "100%", lg: "35%" }}
            pl={{ base: "0px", md: "40px", "2xl": "110px" }}
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            textAlign={{ base: "center", lg: "left" }}
          >
            {isLargerThan415 ? (
              <>
                <Flex
                  w="full"
                  flexDirection={{ base: "row", lg: "column" }}
                  justifyContent={{ base: "center" }}
                >
                  <Heading
                    as="span"
                    size="h1"
                    fontSize={{ base: "4xl", md: "6xl" }}
                    lineHeight={{ base: "42px", md: "70px" }}
                  >
                    Why{" "}
                  </Heading>

                  <Heading
                    as="span"
                    size="h1"
                    variant="underline"
                    fontSize={{ base: "4xl", md: "6xl" }}
                    lineHeight={{ base: "42px", md: "70px" }}
                  >
                    {" "}
                    Ink Whale
                  </Heading>
                </Flex>
                <Text
                  w="full"
                  textAlign={{ base: "center", lg: "left" }}
                  mt={{ base: "14px", md: "16px" }}
                  mb={{ base: "28px", md: "44px" }}
                >
                  Staking and Yield Farming platform on Aleph Zero
                </Text>
              </>
            ) : null}
            <Flex w="full" justifyContent={{ base: "center" }}>
              <Button
                marginRight="4"
                w="174px"
                onClick={() => window.open("http://a0.inkwhale.net/", "_blank")}
              >
                Mainnet Access
              </Button>
              <Button
                mx={{ base: "auto", lg: "unset" }}
                w="174px"
                onClick={() =>
                  window.open("https://testnet.inkwhale.net", "_blank")
                }
              >
                Testnet Demo
              </Button>
            </Flex>
          </Box>
        </Flex>
        <div id="how-to-earn"></div>
      </SectionContainer>

      {/* Earn with Ink Section */}
      <SectionContainer
        textAlign={{ base: "center", xl: "center" }}
        px={{ base: "20px", xl: "135px" }}
        pt={{ base: "10px", xl: "32px" }}
        pb={{ base: "10px", xl: "100px" }}
        mt={{ base: "42px", xl: "60px" }}
        position="relative"
      >
        <Flex flexDirection={{ base: "column" }} width="full" minH="606px">
          <Flex
            data-aos="fade-up"
            data-aos-delay="100"
            w="full"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              as="span"
              size="h1"
              fontSize={{ base: "4xl", md: "6xl" }}
              lineHeight={{ base: "42px", md: "70px" }}
            >
              How to earn more
            </Heading>{" "}
          </Flex>

          <Flex
            data-aos="fade-up"
            data-aos-delay="100"
            w="full"
            alignItems="center"
            justifyContent="center"
          >
            <Heading
              as="span"
              size="h1"
              fontSize={{ base: "4xl", md: "6xl" }}
              lineHeight={{ base: "42px", md: "70px" }}
            >
              with{" "}
              <Heading
                as="span"
                size="h1"
                variant="underline"
                fontSize={{ base: "4xl", md: "6xl" }}
                lineHeight={{ base: "42px", md: "70px" }}
              >
                Ink Whale?
              </Heading>
            </Heading>
          </Flex>

          <Flex
            data-aos="fade-up"
            data-aos-delay="200"
            minH={{ base: "fit-content", xl: "627px" }}
            position="relative"
            pt={{ base: "32px", xl: "50px" }}
            // alignItems="start"
            alignItems="center"
            justifyContent="center"
            flexDirection={"column"}
          >
            {/* Big Wallet Image */}
            <Image
              width="full"
              height="full"
              maxWidth={{
                base: "160px",
                // md: "400px",
                // lg: "500px",
                // xl: "600px",
                "2xl": "394px",
              }}
              maxHeight={{
                base: "185px",
                // md: "358px",
                // lg: "448px",
                // xl: "537px",
                "2xl": "453px",
              }}
              src={featureWalletImage}
            />
            {isLargerThan770 ? (
              <Flex
                position="absolute"
                bottom="18px"
                // bg="transparent"
                h="255px"
                w="967px"
                mx="auto"
                bgImage={featureCurveImage}
              >
                <Flex position="absolute" top="-213px" left="-94px">
                  <FeatureSmallCard {...earnSmallCardData[0]} />
                </Flex>
                <Flex position="absolute" bottom="-84px" left="105px">
                  <FeatureSmallCard {...earnSmallCardData[1]} />
                </Flex>
                <Flex position="absolute" bottom="-84px" right="88px">
                  <FeatureSmallCard {...earnSmallCardData[2]} />
                </Flex>
                <Flex position="absolute" top="-213" right="-112px">
                  <FeatureSmallCard {...earnSmallCardData[3]} />
                </Flex>
              </Flex>
            ) : (
              <VStack
                data-aos="fade-up"
                data-aos-delay="200"
                spacing="16px"
                mt="68px"
              >
                {earnSmallCardData.map((item, idx) => (
                  <FeatureSmallCard {...item} key={idx} />
                ))}
              </VStack>
            )}
          </Flex>
        </Flex>
        <div id="tokenomics"></div>
      </SectionContainer>
    </Flex>
  );
}
const featuresData = [
  {
    icon: <FeatureIcon1 />,
    title: "Sponsored by ArtZero",
    content:
      "The project is supported by ArtZero NFT marketplace and is compatible with NFTs listed there",
  },
  {
    icon: <FeatureIcon2 />,
    title: "Substrate-based dapp",
    content:
      "Using Ink! Smart Contract, Ink Whale runs on Aleph Zero for now but can be deployed on any Parachains support Ink! in Polkadot ecosystem such as Astar.",
  },
  {
    icon: <FeatureIcon9 />,
    title: "LaunchPad",
    content:
      "Comprehensive platform to launch new tokens, crypto projects and raise liquidity on Aleph Zero",
  },
  {
    icon: <FeatureIcon3 />,
    title: "100% Permissionless",
    content:
      "Psp22(erc20) token, staking pool and yield farm can be created by anyone via our smart contracts",
  },

  {
    icon: <FeatureIcon4 />,
    title: "Low risk investment",
    content:
      "There is NO permanent loss as rewards are calculated at fixed rate and pool creators have to provide liquidity for the rewards.",
  },

  {
    icon: <FeatureIcon10 />,
    title: "Orderbook DEX",
    content:
      "On-chain order book that allows users to execute orders directly with a smart contract while still enabling flexibility with pricing and order sizes",
  },
];

const earnSmallCardData = [
  {
    color: "#FFA0D2",
    icon: <FeatureIcon5 />,
    title: "Select a staking pool/ yield farm",
  },
  {
    color: "#CDA1F6",

    icon: <FeatureIcon6 />,
    title: "Lockup your tokens/ NFTs",
  },
  {
    color: "#FFDA6E",

    icon: <FeatureIcon7 />,
    title: "Unlock whenever you want",
  },

  {
    color: "#F59A86",

    icon: <FeatureIcon8 />,
    title: "Earn Rewards instantly",
  },
];
