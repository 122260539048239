import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Heading,
  Show,
  useMediaQuery,
} from "@chakra-ui/react";

import ReactFloaterJs from "react-floaterjs";
import heroImage from "assets/img/hero/hero-image.png";
import heroGadgetWalletImage from "assets/img/hero/hero-gadget-wallet.png";
import heroGadgetCoinImage from "assets/img/hero/hero-gadget-coin.png";
import heroGadgetChartImage from "assets/img/hero/hero-gadget-chart.png";
import heroGadgetSquiggleLineImage from "assets/img/hero/hero-gadget-squiggle-line.png";
import heroBg from "assets/img/hero/hero-bg.svg";

import SectionContainer from "pages/landing-page/components/SectionContainer";
import {
  HeroDotGroupTopLeftIcon,
  HeroDotGroupBottomRightIcon,
} from "components/icons/Icons";

export default function Hero() {
  const [isLargerThan1441] = useMediaQuery("(min-width: 1441px)");

  return (
    <Flex
      id="hero-banner-section"
      zIndex="2"
      w="100%"
      direction={["row"]}
      overflow="hidden"
      bgImage={heroBg}
      position="relative"
      backgroundRepeat="no-repeat"
      backgroundPosition="bottom"
      minH={{ base: "fit-content", xl: "817px" }}
      backgroundSize={isLargerThan1441 ? "cover" : "contain"}
      // {["672px", "817px"]}
    >
      <HeroDotGroupTopLeftIcon
        left="0"
        position="absolute"
        top={["60%", "50px"]}
        width={["30px", "44px"]}
        height={["82px", "120px"]}
      />
      <Show above="lg">
        <HeroDotGroupBottomRightIcon
          height="44px"
          width="120px"
          right="-40px"
          bottom={"-20px"}
          position={{ base: "relative", lg: "absolute" }}
        />
      </Show>
      <SectionContainer
        textAlign={{ base: "center", xl: "left" }}
        px={{ base: "20px", xl: "135px" }}
        pt={{ base: "10px", xl: "32px" }}
        mt={{ base: "20px", xl: "60px" }}
        position="relative"
      >
        <Flex
          width="full"
          minH="606px"
          flexDirection={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "start" }}
        >
          <Box
            data-aos="fade-up"
            data-aos-delay="100"
            w="full"
            maxW={{ base: "100%", md: "40%" }}
          >
            <Heading
              as="span"
              size="h1"
              variant="underline"
              fontSize={{ base: "4xl", lg: "6xl" }}
              lineHeight={{ base: "42px", lg: "70px" }}
            >
              {" "}
              Ink Whale
            </Heading>
            <Heading
              as="span"
              size="h1"
              fontSize={{ base: "4xl", lg: "6xl" }}
              lineHeight={{ base: "42px", lg: "70px" }}
            >
              {" "}
              lets you earn fixed interest.
            </Heading>{" "}
            <Text
              fontWeight="normal"
              mt={{ base: "14px", md: "16px" }}
              mb={{ base: "28px", md: "44px" }}
            >
              {" "}
              And other rewards for locking up your tokens or NFT on Aleph Zero
              Network.
            </Text>
            <Flex >
              <Button
                marginRight="4"
                w="174px"
                onClick={() => window.open("http://a0.inkwhale.net/", "_blank")}
              >
                Mainnet Access
              </Button>
              <Button
                mx={{ base: "auto", lg: "unset" }}
                w="174px"
                onClick={() =>
                  window.open("https://testnet.inkwhale.net", "_blank")
                }
              >
                Testnet Demo
              </Button>
            </Flex>
          </Box>

          {/* Dolphin Image */}
          <Image
            data-aos="fade-up"
            data-aos-delay="200"
            transform="rotate(1deg)"
            mt={{ base: "54px", md: "auto" }}
            width="full"
            height="full"
            maxWidth={{
              sm: "318px",
              md: "400px",
              lg: "500px",
              // xl: "600px",
              xl: "676px",
            }}
            maxHeight={{
              sm: "292px",
              md: "358px",
              lg: "448px",
              // xl: "537px",
              xl: "606px",
            }}
            src={heroImage}
            right={{
              base: "60px",
              md: "60px",
              lg: "60px",
              xl: "120px",
            }}
            top="22px"
            position={{ base: "inset", md: "absolute" }}
          />

          {/* hero-gadget-squiggle-line */}
          <Show above="lg">
            <Box
              duration="200ms"
              left="45px"
              bottom="166px"
              position={{ base: "absolute", lg: "absolute" }}
            >
              <ReactFloaterJs>
                <Image src={heroGadgetSquiggleLineImage} />
              </ReactFloaterJs>
            </Box>
          </Show>

          {/* hero-gadget-coin */}
          <Box
            duration="200ms"
            w={["45px", "77px"]}
            left={{
              base: "3%",
              md: "36%",
            }}
            bottom={["72px", "212px"]}
            position={{ base: "absolute", lg: "absolute" }}
          >
            <ReactFloaterJs>
              <Image src={heroGadgetCoinImage} />
            </ReactFloaterJs>
          </Box>

          {/* hero-gadget-chart */}
          <Box
            duration="200ms"
            w={{
              base: "58px",
              md: "118px",
            }}
            right={{
              base: "12px",
              md: "122px",
            }}
            top={["48%", "-10px"]}
            position={{ base: "absolute", lg: "absolute" }}
          >
            <ReactFloaterJs>
              <Image src={heroGadgetChartImage} />
            </ReactFloaterJs>
          </Box>

          {/* hero-gadget-wallet */}
          <Box
            w={{
              base: "110px",
              md: "154px",
            }}
            duration="200ms"
            right={["-125px", "-70px"]}
            bottom={["10px", "210px"]}
            position={{ base: "absolute", lg: "absolute" }}
          >
            <ReactFloaterJs>
              <Image src={heroGadgetWalletImage} />
            </ReactFloaterJs>
          </Box>
        </Flex>{" "}
        <div id="why-us"></div>
      </SectionContainer>
    </Flex>
  );
}
