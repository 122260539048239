import {
  Flex,
  Box,
  Heading,
  ListItem,
  ListIcon,
  List,
  Image,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import React from "react";
import { BsCircleFill } from "react-icons/bs";

export default function RoadmapCard({
  title,
  imageUrl,
  contentList,
  bgColor,
  ...rest
}) {
  return (
    <Card
      textAlign="left"
      maxW={{ base: "full", xl: "570px" }}
      minH="365px"
      borderRadius="10px"
      bg="#fff"
      p="0px"
      w="100%"
      display="flex"
      overflow="hidden"
      position="relative"
      boxShadow="0px 20px 60px #F9F1FF"
      {...rest}
    >
      <Flex
        minH="220px"
        bg={bgColor}
        w={{ base: "full" }}
        alignItems="center"
        justifyContent="center"
      >
        <Image h={{ base: "150px", xl: "177px" }} src={imageUrl} />
      </Flex>
      <Box p="25px 22px" w={{ base: "full" }} textAlign="left">
        <Heading
          as="h4"
          fontSize={{ base: "2xl", xl: "3xl" }}
          fontWeight="700"
          mt={{ base: "18px" }}
          mb={{ base: "8px" }}
        >
          {title}
        </Heading>
        <List>
          {contentList?.map((item, idx) => (
            <ListItem my="4px" key={idx}>
              <ListIcon w="6px" as={BsCircleFill} color="decoration.5" />
              {item}
            </ListItem>
          ))}
        </List>
      </Box>
    </Card>
  );
}
