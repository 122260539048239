import { Text, Flex, Heading } from "@chakra-ui/react";

import Card from "components/card/Card";
import IconBox from "components/icons/IconBox";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

export default function FeatureSwipeCard({ dataList, ...rest }) {
  return (
    <>
      <Swiper
        id="feature-swipe-card"
        spaceBetween={20}
        slidesPerView={1.1}
        className="mySwiper"
      >
        {dataList?.map(({ title, icon, content }) => {
          return (
            <SwiperSlide key={title}>
              <Card
                textAlign="left"
                maxW="330px"
                borderRadius="10px"
                bg="#fff"
                p="30px 25px"
                w="100%"
                h="full"
                minH="342px"
                direction={{ base: "column" }}
                overflow="hidden"
                position="relative"
                boxShadow="0px 20px 60px #F9F1FF"
                {...rest}
              >
                <Flex alignItems="center">
                  <IconBox icon={icon} h="80px" w="82px" />
                </Flex>
                <Heading
                  fontSize="2xl"
                  as="h4"
                  size="h4"
                  mt={{ base: "18px" }}
                  mb={{ base: "8px" }}
                >
                  {title}
                </Heading>
                <Text fontSize="17px" fontWeight="400">
                  {content}{" "}
                </Text>
              </Card>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
