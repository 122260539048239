import { Flex } from "@chakra-ui/react";
import React from "react";

function SectionContainer({ children, ...rest }) {
  return (
    <Flex direction="column" mx="auto" maxW="1440px" w="full" {...rest}>
      {children}
    </Flex>
  );
}

export default SectionContainer;
