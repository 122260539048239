import {
  Button,
  Circle,
  Divider,
  Heading,
  HStack,
  Link,
  SimpleGrid,
  Square,
  Text,
} from "@chakra-ui/react";

import React from "react";

export default function ElementPage() {
  return (
    <SimpleGrid gap="10px" p={{ base: "30px", md: "80px", xl: "80px" }}>
      <Heading as="h2" size="h2">
        Colors
      </Heading>
      <Heading as="h5" size="h5">
        Primary
      </Heading>
      <HStack spacing="0">
        <Square size="50px" bg="brand.100" />
        <Square size="50px" bg="brand.200" />
        <Square size="50px" bg="brand.300" />
        <Square size="50px" bg="brand.400" />
        <Square size="50px" bg="brand.500" />
        <Square size="50px" bg="brand.600" />
        <Square size="50px" bg="brand.700" />
        <Square size="50px" bg="brand.800" />
        <Square size="50px" bg="brand.900" />
      </HStack>

      <HStack>
        <Circle size="100px" bg="brand.500" />
      </HStack>
      <Heading as="h5" size="h5">
        Text
      </Heading>
      <HStack>
        <Circle size="100px" bg="text.1" />
        <Circle size="100px" bg="text.2" />
        <Circle size="100px" bg="text.3" border="1px solid lightgrey" />
      </HStack>
      <Heading as="h5" size="h5">
        Background
      </Heading>
      <HStack>
        <Circle size="100px" bg="bg.1" />
        <Circle size="100px" bg="bg.2" />
        <Circle size="100px" bg="bg.3" />
        <Circle size="100px" bg="bg.4" />
        <Circle size="100px" bg="bg.5" />
        <Circle size="100px" bg="bg.6" border="1px solid lightgrey" />
      </HStack>
      <Heading as="h5" size="h5">
        Border
      </Heading>
      <HStack>
        <Circle size="100px" bg="border" />
      </HStack>
      <Heading as="h5" size="h5">
        Decoration
      </Heading>
      <HStack>
        <Circle size="100px" bg="decoration.1" />
        <Circle size="100px" bg="decoration.2" />
        <Circle size="100px" bg="decoration.3" />
        <Circle size="100px" bg="decoration.4" />
        <Circle size="100px" bg="decoration.5" />
      </HStack>
      <Divider />
      <Heading as="h2" size="h2">
        Typography
      </Heading>
      <>
        <Heading my="20px" as="h1" size="display" noOfLines={1}>
          Display -Bold-60/70
        </Heading>
        <Heading my="20px" as="h1" size="h1">
          Heading 01-Outfit-Bold-36/auto
        </Heading>
        <Heading my="20px" as="h2" size="h2">
          Heading 02-Outfit-Bold-30/auto
        </Heading>
        <Heading my="20px" as="h3" size="h3">
          Heading 03-Outfit-Bold-24/auto
        </Heading>
        <Heading my="20px" as="h4" size="h4">
          Heading 04-Outfit-Bold-20/auto
        </Heading>
        <Heading my="20px" as="h5" size="h5">
          Heading 05-Outfit-Bold-18/auto
        </Heading>{" "}
        <Heading my="20px" as="h5" size="h6">
          Heading 06-Outfit-Bold-16/auto
        </Heading>
        <Heading my="20px" as="h6" size="menu">
          Menu-Outfit-Bold-16/auto
        </Heading>
        <Text>Body medium-Outfit-Regular -18/30</Text>
        <Text fontSize="md" lineHeight="28px">
          Body small-Outfit-Regular -16/28
        </Text>
      </>
      <HStack>
        <Button w="200px" variant="primary">
          Button Primary
        </Button>
        <Button isDisabled w="200px" variant="primary">
          Button Primary
        </Button>
      </HStack>
      <HStack>
        <Button w="200px" variant="secondary">
          Button Secondary
        </Button>
        <Button isDisabled w="200px" variant="secondary">
          Button Secondary
        </Button>
      </HStack>
      <HStack>
        <Button w="200px" variant="outline">
          Button outline
        </Button>
        <Button isDisabled w="200px" variant="outline">
          Button outline
        </Button>
      </HStack>
      <Link>Link</Link>
    </SimpleGrid>
  );
}
