import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";

import LandingPage from "pages/landing-page";
import ElementPage from "pages/element";

const routes = [
  {
    name: "Landing Page",
    layout: "/default",
    path: "/",
    component: LandingPage,
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  },
  {
    name: "Element",
    layout: "/default",
    path: "/element",
    component: ElementPage,
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  },
];

export default routes;
