import { Flex, Link, Text, Show } from "@chakra-ui/react";

import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import routes from "routes.js";
export default function NavbarLinksLandingPage(props) {
  const { secondary } = props;

  const [currentAnchor, setCurrentAnchor] = useState("");

  useEffect(() => {
    const href = window.location.href;
    const index = href.indexOf("#");
    const length = href.length;

    const shortenUrl = href.slice(index, length);

    shortenUrl === "#/"
      ? setCurrentAnchor("")
      : setCurrentAnchor(shortenUrl.replace("/", ""));
  }, []);

  return (
    <Flex
      id="abc"
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      justifyContent="end"
      bg="transparent"
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
    >
      <SidebarResponsive routes={routes} />
      <Show above="md">
        <Flex bg="transparent">
          {menuListData?.map(({ title, href }) => (
            <Flex
              p="6px 10px"
              bg={
                (!currentAnchor && href === "#hero") || currentAnchor === href
                  ? "bg.1"
                  : "transparent"
              }
              borderRadius="5px"
              key={title}
              ml={{ base: "20px", md: "20px" }}
            >
              <Link
                onClick={() => setCurrentAnchor(href)}
                bg="transparent"
                textDecoration="none"
                fontWeight="600"
                color={"text.1"}
                href={href}
              >
                <Text bg="transparent" fontSize="md">
                  {title}
                </Text>
              </Link>
            </Flex>
          ))}
        </Flex>
      </Show>
    </Flex>
  );
}

NavbarLinksLandingPage.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};

export const menuListData = [
  {
    title: "Home",
    href: "#hero",
  },
  {
    title: "Why us",
    href: "#why-us",
  },
  {
    title: "How to earn",
    href: "#how-to-earn",
  },
  {
    title: "Tokenomics",
    href: "#tokenomics",
  },
  {
    title: "Roadmap",
    href: "#roadmap",
  },
  {
    title: "Docs",
    href: "https://docs.inkwhale.net/",
  },
];
