import { Flex, Box, Heading, Square } from "@chakra-ui/react";

import Card from "components/card/Card";
import { FeatureIconBackground } from "components/icons/Icons";
import { FeatureIconBorder } from "components/icons/Icons";
import React from "react";

export default function FeatureSmallCard({ title, icon, color, ...rest }) {
  return (
    <Card
      bg="transparent"
      id="card"
      maxW={{ base: "full", xl: "200px" }}
      maxH="220px"
      p="4px"
      w="100%"
      display="flex"
      textAlign={{ base: "left", xl: "center" }}
      justifyContent={{ base: "start", xl: "center" }}
      alignItems="center"
      flexDirection={{ base: "row", xl: "column" }}
      overflow="hidden"
      position="relative"
      {...rest}
    >
      <Flex alignItems="center">
        <Box
          id="card-wrapper"
          color="#FFF"
          width={{ base: "60px", xl: "114px" }}
          height={{ base: "60px", xl: "114px" }}
          position={"relative"}
        >
          <FeatureIconBorder
            sx={{
              "#card-wrapper:hover &": {
                color,
              },
            }}
          />
          <FeatureIconBackground
            zIndex="-1"
            top="-2px"
            left="-2px"
            width={{ base: "64px", xl: "118px" }}
            height={{ base: "64px", xl: "118px" }}
            position={"absolute"}
            color="border"
            sx={{
              "#card-wrapper:hover &": {
                color: "#ffffff00",
              },
            }}
          />
          <Square
            top="0%"
            left="0%"
            width={{ base: "60px", xl: "114px" }}
            height={{ base: "60px", xl: "114px" }}
            position={"absolute"}
            sx={{
              "#card-wrapper:hover &": {
                color: "#FFF",
              },
            }}
          >
            {icon}
          </Square>
        </Box>
      </Flex>
      <Heading
        as="h3"
        size="h3"
        mt={{ xl: "18px" }}
        mb={{ xl: "8px" }}
        ml={{ base: "8px", xl: "0px" }}
      >
        {title}
      </Heading>
    </Card>
  );
}
