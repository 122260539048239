import { Flex } from "@chakra-ui/react";

import Features from "./sections/features";
import Hero from "./sections/hero";
import Tokenomics from "./sections/tokenomics";

export default function LandingPage() {
  return (
    <Flex id="landing-page" flexDirection={["column"]}>
      <Hero />
      <Features />
      <Tokenomics />
    </Flex>
  );
}
